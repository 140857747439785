import React from "react";

const WorkProcessTwo = () => {
  return (
    <>
      {/*==================== Work Process Two start ====================*/}
      <div className='work-process-area pd-top-90 pd-bottom-90'>
        <div className='container'>
          <div className='section-title text-center'>
            <h6 className='sub-title'>MAXIMISING SAFETY</h6>
            <h2 className='title'>
              Key Benefits of <span>QRIQ’s</span> Vehicle QR Code
            </h2>
          </div>
          <div className='row'>
            <div className='col-xl-4 col-md-6'>
              <div className='single-work-process-inner'>
                <div className='thumb mb-3'>
                  <img src='assets/img/icon/safety.png' alt='img' />
                </div>
                <div className='details'>
                  <p className='process-count'>Safety 01</p>
                  <h5 className='mb-3'>Proactive Parking Management</h5>
                  <p className='content'>
                  Get real-time alerts for incorrect parking. 
                  </p>
                </div>
              </div>
            </div>
            <div className='col-xl-4 col-md-6'>
              <div className='single-work-process-inner'>
                <div className='thumb mb-3'>
                  <img src='assets/img/icon/safety.png' alt='img' />
                </div>
                <div className='details'>
                  <p className='process-count'>Safety 02</p>
                  <h5 className='mb-3'>Swift Towing Updates</h5>
                  <p className='content'>
                  Know immediately if your vehicle is towed. 
                  </p>
                </div>
              </div>
            </div>
            <div className='col-xl-4 col-md-6'>
              <div className='single-work-process-inner'>
                <div className='thumb mb-3'>
                  <img src='assets/img/icon/safety.png' alt='img' />
                </div>
                <div className='details'>
                  <p className='process-count'>Safety 03</p>
                  <h5 className='mb-3'>Critical Emergency Assistance</h5>
                  <p className='content'>
                  Provide bystanders with swift alerts and essential information during accidents.
                  </p>
                </div>
              </div>
            </div>
            <div className='col-xl-4 col-md-6'>
              <div className='single-work-process-inner'>
                <div className='thumb mb-3'>
                  <img src='assets/img/icon/safety.png' alt='img' />
                </div>
                <div className='details'>
                  <p className='process-count'>Safety 04</p>
                  <h5 className='mb-3'>Secure Personal Data</h5>
                  <p className='content'>
                  Robust encryption safeguards your personal details.    
                  </p>
                </div>
              </div>
            </div>

            <div className='col-xl-4 col-md-6'>
              <div className='single-work-process-inner'>
                <div className='thumb mb-3'>
                  <img src='assets/img/icon/safety.png' alt='img' />
                </div>
                <div className='details'>
                  <p className='process-count'>Safety 05</p>
                  <h5 className='mb-3'>Safety for Unattended Situations</h5>
                  <p className='content'>
                  Get immediate notifications for left belongings.
                  </p>
                </div>
              </div>
            </div>


            <div className='col-xl-4 col-md-6'>
              <div className='single-work-process-inner'>
                <div className='thumb mb-3'>
                  <img src='assets/img/icon/safety.png' alt='img' />
                </div>
                <div className='details'>
                  <p className='process-count'>Safety 06</p>
                  <h5 className='mb-3'>Effortless Set-Up</h5>
                  <p className='content'>
                  Easy, hassle-free implementation without any extra apps.  
                  </p>
                </div>
              </div>
            </div>

          </div>
        </div>
      </div>
      {/* ==================== Work Process Two end ====================*/}
    </>
  );
};

export default WorkProcessTwo;
