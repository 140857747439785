import React from "react";

const ContactAreaTwo = () => {
  return (
    <>
      {/* ========================= contact Area Two start =========================*/}
      <div className='contact-area'>
        <div className='container'>
          <div className='contact-inner-1'>
            <div className='row'>
              <div
                className='col-lg-8'
                data-aos='fade-right'
                data-aos-delay='200'
                data-aos-duration='1500'
              >
                <img className='w-100' src='assets/img/bg/9.png' alt='img' />
              </div>
              <div
                className='col-lg-4 '
                data-aos='fade-left'
                data-aos-delay='200'
                data-aos-duration='1500'
              >
                <div className='section-title mb-0'>
                  <h6 className='sub-title'>ON-ROAD SAFETY</h6>
                  <h2 className='title'>Vehicle QR Code in Action</h2>
                  <p className='content'>
                  Ensuring vehicle owners' safety and awareness is at the heart of the QRIQ Vehicle QR Code. Dive into its diverse functionalities designed to keep you informed and connected at all times. 
                  </p>
                  <div
                    className='accordion accordion-inner style-3 accordion-icon-left mt-3'
                    id='accordionExample'
                  >
                    <div className='accordion-item'>
                      <h2 className='accordion-header' id='headingOne'>
                        <button
                          className='accordion-button'
                          type='button'
                          data-bs-toggle='collapse'
                          data-bs-target='#collapseOne'
                          aria-expanded='true'
                          aria-controls='collapseOne'
                        >
                          Real-time Parking Updates
                        </button>
                      </h2>
                      <div
                        id='collapseOne'
                        className='accordion-collapse collapse show'
                        aria-labelledby='headingOne'
                        data-bs-parent='#accordionExample'
                      >
                        <div className='accordion-body'>
                        Incorrectly parked? If your car is parked wrongly, is in a no-parking zone or causing inconvenience, get immediate notifications. Avoid hefty fines and towing troubles with swift alerts.
                        </div>
                      </div>
                    </div>
                    <div className='accordion-item'>
                      <h2 className='accordion-header' id='headingTwo'>
                        <button
                          className='accordion-button collapsed'
                          type='button'
                          data-bs-toggle='collapse'
                          data-bs-target='#collapseTwo'
                          aria-expanded='false'
                          aria-controls='collapseTwo'
                        >
                          Vehicle Towing Alerts
                        </button>
                      </h2>
                      <div
                        id='collapseTwo'
                        className='accordion-collapse collapse'
                        aria-labelledby='headingTwo'
                        data-bs-parent='#accordionExample'
                      >
                        <div className='accordion-body'>
                        No more frantic searches. If your car gets towed, a quick scan will notify the owner instantly. Stay ahead and retrieve your vehicle without hassle.
                        </div>
                      </div>
                    </div>
                    <div className='accordion-item'>
                      <h2 className='accordion-header' id='headingThree'>
                        <button
                          className='accordion-button collapsed'
                          type='button'
                          data-bs-toggle='collapse'
                          data-bs-target='#collapseThree'
                          aria-expanded='false'
                          aria-controls='collapseThree'
                        >
                          Accident & Emergency Response
                        </button>
                      </h2>
                      <div
                        id='collapseThree'
                        className='accordion-collapse collapse'
                        aria-labelledby='headingThree'
                        data-bs-parent='#accordionExample'
                      >
                        <div className='accordion-body'>
                        In the event of an accident, bystanders can scan the QR code, linking them to the owner's family for immediate communication. Along with the owner's details, vital information like blood group is readily available. The integrated system with highway authorities ensures speedy assistance from nearby hospitals and police stations when required, bridging the time-critical gap between incident and assistance.
                        </div>
                      </div>
                    </div>
                    <div className='accordion-item'>
                      <h2 className='accordion-header' id='headingFour'>
                        <button
                          className='accordion-button collapsed'
                          type='button'
                          data-bs-toggle='collapse'
                          data-bs-target='#collapseFour'
                          aria-expanded='false'
                          aria-controls='collapseFour'
                        >
                          Vehicle Status and Alerts
                        </button>
                      </h2>
                      <div
                        id='collapseFour'
                        className='accordion-collapse collapse'
                        aria-labelledby='headingFour'
                        data-bs-parent='#accordionExample'
                      >
                        <div className='accordion-body'>
                        From potential fuel leaks to forgotten keys inside the car, be promptly informed about any situation needing your attention. In scenarios where your vehicle is left unattended or causing inconvenience, traffic personnel or concerned individuals can scan the QR Code and initiate direct communication.
From parking lots to highways, QRIQ's Vehicle QR Code ensures you are always informed, connected, and in control. Safety isn't just a word – it is a QR scan away.

                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      {/*========================= contact-inner Two end =========================*/}
    </>
  );
};

export default ContactAreaTwo;
