import React from "react";
import { FaCheckCircle, FaPlus } from "react-icons/fa";
import { Link } from "react-router-dom";

const AboutAreaTwo = () => {
  return (
    <>
      {/* =============== About Area Two End ===============*/}
      <div className='about-area pd-top-90 pd-bottom-90'>
        <div className='container'>
          <div className='row'>
            <div
              className='col-lg-6 mb-4 mb-lg-0 '
              data-aos='fade-right'
              data-aos-delay='100'
              data-aos-duration='1500'
            >
              <div className='about-thumb-inner'>
                <img
                  className='main-img'
                  src='assets/img/about/10.png'
                  alt='img'
                />
              </div>
            </div>
            <div
              className='col-lg-6 '
              data-aos='fade-left'
              data-aos-delay='100'
              data-aos-duration='1500'
            >
              <div className='section-title mb-0'>
                <h6 className='sub-title'>ABOUT QRIQ</h6>
                <h2 className='title'>
                Introducing Road Safety’s Future: QR Code
                </h2>
                <p className='content mb-4'>
                Navigating today's highways and byways has never been more unpredictable. Our Vehicle QR Code is the solution every car owner needs, blending technology with care to ensure you and your loved ones stay connected during critical moments.
                </p>
                <div className='row'>
                  <div className='col-md-6'>
                    <ul className='single-list-inner style-check style-heading style-check mb-3'>
                      <li className="d-flex">
                        <FaCheckCircle /> Scan in emergencies to instantly connect with a vehicle owner's family.
                      </li>
                      <li className="d-flex">
                        <FaCheckCircle /> Receive immediate notifications for any vehicle issues, ensuring nothing goes unnoticed.
                      </li>
                    </ul>
                  </div>
                  <div className='col-md-6'>
                    <ul className='single-list-inner style-check style-heading style-check mb-3'>
                      <li className="d-flex">
                        <FaCheckCircle /> Encrypted storage prioritizes your data privacy, sharing only essential details.
                      </li>
                      <li className="d-flex">
                        <FaCheckCircle /> Our QR code enables bystanders to transition from mere concern to meaningful assistance.
                      </li>
                    </ul>
                  </div>
                </div>
                <p className='content'>
                We believe in a future where roads are not just pathways connecting A to B, but networks of care and community. The Vehicle QR Code is a step towards that safer, connected future. Always drive with the confidence that, no matter what happens, you are not alone on the road.
                </p>
                {/* <Link className='btn btn-border-base' to='/about'>
                  Discover More <FaPlus />
                </Link> */}
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* =============== About Area Two End ===============*/}
    </>
  );
};

export default AboutAreaTwo;
